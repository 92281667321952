<template>
    <div class="index">
        <!-- <div class="block">
            <el-carousel :interval="4000" type="card" trigger="click" height="532px">
                <el-carousel-item v-for="item in 3" :key="item">
                    <img :src="require(`../assets/imgs/img${item}.jpg`)" alt="">
                </el-carousel-item>
            </el-carousel>
        </div> -->
        <div class="ZoneContent">
            <div class="img_position">

            </div>
            <div class="title">
                推荐信制作与下载
            </div>
            <div class="Subtitle">
                推荐信表单信息一键提交 <br><br>
                实时查看推荐书处理进度 <br><br>
                一键下载推荐信最终结果 <br><br>
            </div>
            <div class="module" @click="Jump('/FormSubmit')">
                立刻填写
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            imgArr:[
                
            ]
        }
    },
    created(){
        
    },
    methods:{
        Jump(URl){
            this.$router.push(URl) 
            
        }
    }
}
</script>

<style lang='scss' scoped>
    .index{
        .block{
            width: 100%;
            height: 532px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .el-carousel__item h3 {
            color: #475669;
            font-size: 14px;
            opacity: 0.75;
            line-height: 150px;
            margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
        }
        
        .el-carousel__item:nth-child(2n+1) {
            background-color: #d3dce6;
        }
        .ZoneContent{
            width: 100%;
            height: 980px;
            height: calc(100vh - 160px);
            background: #41cddc;
            position: relative;
            overflow: hidden;
            .title{
                position: absolute;
                top: 267px; 
                left: 380px;
                top: 10%;
                left: 20%;
                width: 602px;
                transform: translate(0px, 0px);
                backface-visibility: hidden;
                height: auto;
                animation: 0s ease 0s 1 normal none running none;
                color: #fff;
                font-size: 48px;
                font-weight: bold;
                text-align: left;
                font-family: '微软雅黑';
            }
            .Subtitle{
                position: absolute;
                top: 401px;
                left: 380px;
                top: 30%;
                left: 20%;
                width: 530px;
                transform: translate(0px, 0px);
                backface-visibility: hidden;
                height: auto;
                animation: 0s ease 0s 1 normal none running none;
                color: #fff;
                text-align: left;
                overflow: hidden;
                font-size: 20px;
                font-family: '微软雅黑';
                letter-spacing: 10px;
            }
            .module{
                position: absolute;
                box-sizing: border-box;
                top: 630px;
                left: 380px;
                top: 60%;
                left: 20%;
                width: 215px;
                height: 60px;
                border: 10px solid transparent;
                transform: translate(0, 0px);
                backface-visibility: hidden;
                animation: 0s ease 0s 1 normal none running none;
                border: 2px solid #ffffff;
                color: #fff;
                line-height: 60px;
                text-align: center;
                cursor: pointer;
            }
            .img_position{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url('../assets/imgs/backgroundImg.jpg') no-repeat;
                background-size: cover;
                background-position: center;
                background-size: 90%;
            }
        }
    }
</style>